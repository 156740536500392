.footer {
    display: flex;
    justify-content: space-between;
    background-color: #333;
    color: #fff;
    padding: 20px;
    flex-wrap: wrap;
    width: 100%;
}

.footer-left, .footer-right {
    flex: 1;
    margin: 10px;
}

.footer-logo {
    width: 100px;
    margin-bottom: 20px;
}

.footer-contact h3 {
    margin-bottom: 10px;
    font-size: 18px;
}

.footer-contact p {
    margin: 5px 0;
}

.footer-copyright {
    margin-top: 20px;
    font-size: 12px;
}

.footer-right h3 {
    margin-bottom: 10px;
    font-size: 18px;
}

.footer-social-links a {
    margin-right: 15px;
}

.footer-social-links img {
    width: 50px;
    height: 50px;
}

.footer-map {
    margin-top: 20px;
}