.bigconatiner {
  padding-top: 2px;
  padding-bottom: 2px;
}

.profile-card {
  display: flex;
  width: 100%;
  background-color: #222224;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 16px; /* Adjusted margin for better spacing */
  color: #fff;
  flex-direction: row; /* Default layout for desktop */
}

.profile-image {
  position: relative;
  flex: 1;
  margin-right: 20px;
  background-color: #bdbbb2b5;
  padding: 10px;
  border-radius: 10px;
}

.profile-image img {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.role-tag {
  color: black;
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fdd835;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}

.profile-content {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-content h2 {
  margin-bottom: 10px;
  font-size: 2em;
  color: #fdd835;
}

.profile-content p {
  margin-bottom: 10px;
  font-size: 1.1em;
  line-height: 1.6;
}

.profile-content h3 {
  margin-top: 20px;
  font-size: 1.5em;
  color: #fdd835;
  text-align: left;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .profile-card {
      flex-direction: column; /* Stack items on smaller screens */
      align-items: center; /* Center align for better presentation */
  }

  .profile-image {
      margin-right: 0; /* Remove right margin when stacked */
      margin-bottom: 10px; /* Add space below the image */
  }

  .profile-content h2 {
      font-size: 1.5em; /* Adjust heading size for smaller screens */
  }

  .profile-content p {
      font-size: 1em; /* Adjust paragraph size for smaller screens */
  }

  .profile-content h3 {
      font-size: 1.2em; /* Adjust the name size */
  }
}

@media (max-width: 480px) {
  .profile-content h2 {
      font-size: 1.3em; /* Further adjust heading size */
  }

  .profile-content p {
      font-size: 0.9em; /* Further adjust paragraph size */
  }

  .profile-content h3 {
      font-size: 1em; /* Further adjust the name size */
  }
}
